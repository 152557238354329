.email-sent {
    margin-top: 0em;
    text-align: center;
    margin-bottom: 8em;
}

.email-sent .text{
    border: solid 1px gray;
    border-radius: 10px;
    padding: 2em;
    margin-top: 1em;
}

.email-sent span{
    color: green;
    font-size: 3em;
    text-align: center;
    width: 100%;
    font-weight: bold;
}