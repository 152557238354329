.header {
    padding: .5em .5em;
    display: flex;
    background-color: white;
    color: #666;
}

.header-title {
    font-size: 2em;
    border-right: 2px solid #666;
    padding-right: 1em;
}

.title {
    cursor: pointer;
    font-weight: 500;
    margin-left: 1em;
}

.header-contact,
.header-home,
.header-events {
    display: flex;
    align-items: center;
    margin-left: 3em;
    font-size: 1.3em;
    cursor: pointer;
}

.header-nav {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* MOBILE VIEW */

@media screen and (max-width: 768px) {
    .header {
        margin-left: 0em;
        display: block;
    }
    .header-contact,
    .header-home,
    .header-events {
        font-size: 1em;
        margin-left: 0.5em;
        display: inline-block;
    }
    .header-title {
        display: block;
        width: 100vw;
        max-width: 100% !important;
        font-size: 1.8em !important;
        border-right: none;
        margin: auto;
        padding: 0;
        margin-left: 0;
        text-align: center;
    }
    .header-contact,
    .header-home,
    .header-events {
        font-size: .9em;
        margin-left: 1em;
    }
    .header-nav {
        text-align: center;
    }
}

@media screen and (max-width: 1124px) {
    .header {
        margin-left: 0em;
        display: block;
    }
    .header-contact,
    .header-home,
    .header-events {
        font-size: 1.7em;
        margin-left: 0.5em;
        display: inline-block;
    }
    .header-title {
        font-size: 2.8em !important;
        text-align: center;
        padding: 0;
        border-right: none;
    }
    .header-nav {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-contact,
    .header-home,
    .header-events {
        font-size: 2em;
        margin-left: 1em;
    }
}

@media screen and (max-width: 700px) {
    .header-title {
        font-size: 2em !important;
    }
    .header-contact,
    .header-home,
    .header-events {
        font-size: 1em;
    }
}


