.home {
    display: block;
    height: 100%;
}

.link {
    color: #3581B8;
    cursor: pointer;
}

.link::marker {
    color: initial;
}


/* Home Banner*/

.home-photo-wrapper {
    background-color: #3581B8;
    position: relative;
    text-align: center;
}

.home-photo-wrapper img {
    max-height: 45vh;
    width: 50vw;
}

.motto {
    font-family: 'Bebas Neue';
    position: absolute;
    bottom: 0.5em;
    color: #F3F8F2;
    font-size: 5em;
    width: 100%;
}


/* MISSION STATEMENT */

.information {
    margin-top: 2em;
    max-width: 55vw;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}

.home .details {
    background-color: #EBE9E9;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    text-align: initial;
    margin-top: 3em;
}

.details ul {
    margin-bottom: 0;
}

.home .details h3 {
    color: #FCB07E;
}

.mission-statement h2 {
    color: #3581B8;
    font-weight: bold;
    font-family: 'Bebas Neue';
    font-size: 3em;
}


/* PHOTO GALLERY */

.photo-gallery {
    margin-top: 5em;
    margin-bottom: 5em;
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
}


/* FAQ */

.faq {
    margin-top: 1em;
    max-width: 35vw;
    margin-right: 3em;
    margin-bottom: 2em;
}

.faq>p {
    font-size: 1.1em;
}

.faq h2 {
    color: #3581B8;
    font-weight: bold;
    font-family: 'Bebas Neue';
    font-size: 3em;
}

.faq button {
    display: block;
    margin-right: auto;
    width: 15vw;
    height: 1.7em;
    background-color: #FCB07E;
    color: #F3F8F2;
    border: none;
    font-weight: bold;
    font-family: 'Bebas Neue';
    font-size: 2em;
    margin-top: 2em;
}

.faq button:focus+.btn-primary,
.faq button:focus {
    background-color: #FCB07E;
    color: #F3F8F2;
    border: none;
    box-shadow: none;
}

#collapse-text {
    background-color: #EBE9E9;
    margin-top: 2em;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: .5em !important;
}


/* ENROLLMENT */
.faq-enroll-wrapper {
    display: flex;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

.img-content-wrapper {
    position: relative;
}

.enrollment-content {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba(53, 129, 184, 0.6)
}

.enrollment-content .meta {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10em;
}

.enrollment-content h2 {
    font-size: 3.8em;
    font-weight: bold;
    font-family: 'Bebas Neue';
    color: #F3F8F2;
}

.enrollment-content p {
    font-size: 2em;
    font-weight: bold;
    font-family: 'Bebas Neue';
    color: #F3F8F2;
}

.enrollment-content .download-button{
    background-color: #3581B8;
    color: #F3F8F2;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Bebas Neue';
    font-size: 1.5em;
    width: 15vw;
    padding: 0.5em;
    display: block;
    margin: auto;
}


/* VOLUNTEERS */

.volunteers {
    background-color: #EBE9E9;
    padding-bottom: 2em;
    padding-top: 4em !important;
    margin-top: 5em;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
}

.volunteer-wrapper {
    max-width: 62vw;
    margin-left: auto;
    margin-right: auto;
}

.volunteer-wrapper h2 {
    font-size: 2.5em;
    color: #FCB07E;
    font-weight: bold;
}

.volunteer-profile {
    display: flex;
    margin-top: 2em;
    margin-bottom: 2em;
}

.volunteer-profile>* {
    display: inline-block;
}

.volunteer-profile>div {
    margin-left: 3em;
}

.volunteer-profile img {
    width: 250px;
    height: 250px;
}

.other-volunteer img {
    width: 230px;
    height: 230px;
}

.vol-name {
    font-size: 2em;
    font-family: 'Bebas Neue';
    color: #3581B8;
}

.other-volunteers {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: white;
    margin-top: 3em;
    margin-bottom: 3em;
    max-width: 65vw;
}

.other-volunteers h2 {
    font-size: 2.5em;
    color: #FCB07E;
    font-weight: bold;
    margin-bottom: 1em;
}

.vol-table tr {
    border-style: none !important;
    border: none;
    border-bottom: none;
    border-color: transparent;
}

@media screen and (max-width: 1500px) {
    .other-volunteer img {
        width: 200px;
        height: 200px;
    }
    .other-volunteers {
        max-width: 80vw;
    }
    .enroll img {
        width: 45vw;
        height: 50vh;
    }
    .enrollment-content .meta {
        margin-top: 10%;
        max-width: 95%;
    }

    .enrollment-content .download-button{
        width: 50%;
    }
}

@media screen and (max-width: 1024px) {
    .other-volunteer img {
        width: 150px;
        height: 150px;
    }
    .other-volunteers {
        max-width: 85vw;
    }
    .faq {
        margin-top: 0em;
        max-width: 80vw;
    }

    .enrollment-content .meta {
        font-size: .8em;
    }
}

@media screen and (max-width: 950px) {
    .faq-enroll-wrapper{
        display: block;
    }

    .enroll img {
        width: 100%;
    }

    .enrollment-content .meta {
        font-size: 1em;
    }

    .enrollment-content .meta {
        margin-top: 15%;
    }
    .faq button{
        margin-left: auto;
        width: 8em;
    }
    
}


@media screen and (max-width: 750px) {
    .motto {
        font-size: 2em;
    }
    .home-photo-wrapper img {
        width: 100vw;
    }
    .information,
    .faq {
        max-width: 80vw;
    }
    .faq {
        margin-bottom: 4em;
        margin-right: auto;
    }
    .faq-enroll-wrapper {
        display: block;
    }
    .enroll img {
        width: 100%;
        height: 400px;
    }
    .enrollment-content h2 {
        font-size: 3em;
    }
    .enrollment-content p {
        font-size: 1.5em;
    }
    .enrollment-content .meta {
        margin-top: 8em;
    }
    .faq button {
        width: 100%;
        margin-top: 1em;
    }
    .volunteer-profile>* {
        display: block;
        width: 100%;
    }
    .volunteer-profile {
        display: block;
        margin-bottom: 3em;
    }
    .volunteer-profile img {
        margin-left: auto;
        margin-right: auto
    }
    .volunteer-profile>div {
        margin-top: 1em;
        text-align: justify;
        margin-left: 0;
    }
    p.vol-name {
        text-align: center;
        margin-bottom: 0;
    }
    img.desktop {
        display: none !important;
    }
    .vol-photo-wrapper {
        display: flex;
    }
    .other-volunteer img {
        width: 100px;
        height: 100px;
    }
    .other-volunteers .vol-name {
        font-size: 1em;
    }
    .other-volunteers td {
        padding: 0 !important;
    }
    .enrollment-content .download-button {
        width: 70%;
    }
}

@media screen and (max-width: 550px) {
    .other-volunteers,
    table.vol-table.table,
    .other-volunteers h2 {
        max-width: 80vw;
    }
}

@media screen and (max-width: 475px) {
    .other-volunteers,
    table.vol-table.table,
    .other-volunteers h2 {
        max-width: 90vw;
    }
    .other-volunteer img {
        width: 80px;
        height: 80px;
    }
    .faq-enroll-wrapper {
        display: block;
    }
}

@media screen and (max-width: 375px) {
    .header-title {
        font-size: 1em !important;
        margin-left: 0em;
        max-width: 21vw !important;
    }
    .other-volunteer img {
        width: 80px;
        height: 80px;
    }
    .other-volunteers td {
        padding: 0 !important;
    }
    .other-volunteers .vol-name {
        font-size: .7em;
    }
    .other-volunteers,
    table.vol-table.table,
    .other-volunteers h2 {
        max-width: 90vw;
    }
    .faq-enroll-wrapper {
        display: block;
    }
}