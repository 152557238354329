.text-over-img{
 position: relative;
}

.text-over-img .text{
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
}

.text-over-img .text p {
    margin-top: 33%;
    margin-bottom: 0;
}

.text-over-img img {
    max-height: 600px;
    width: 100%;
}

