/* EVENTS PAGE */
.annual-trip {
    display: block;
    height: 100%;
    text-align: center;
}

.annual-trip-temp {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2em;
}

@media screen and (max-width: 750px) {
    .annual-trip-temp {
        position: relative;
        font-size: 2em;
        top: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25vh;
        left: auto;
    }
  }