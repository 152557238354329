/* SUPPORT PAGE */

.support {
    display: block;
    height: 100%;
    text-align: center;
}

.support-banner .col {
    padding: 0;
}

.support .text {
    background-color: rgba(53, 129, 184, 0.6);
    font-size: 3.8em;
    font-weight: bold;
    font-family: 'Bebas Neue';
    color: #F3F8F2;
}

.support-why {
    margin-top: 2em;
    max-width: 55vw;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}

.support-why h2 {
    color: #3581B8;
    font-weight: bold;
    font-family: 'Bebas Neue';
    font-size: 3em;
}

.support-info {
    background-color: #EBE9E9;
    max-width: 55vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    text-align: initial;
    margin-top: 3em;
}

.support-info .row{
    justify-content: center;
    align-content: center;
    text-align: center;
    align-items: center;
}

.support-info p{
    margin-bottom: 0;
}

.support-cash.col-md-3 {
    margin-bottom: 4em;
}

@media screen and (max-width: 500px) {
    .support .text {
        font-size: 1.8em;
    }

    .support-why, .support-info{
        max-width: 90vw;
    }

    .support-why h2 {
        font-size: 1.8em;
    }

    .support-span {
        margin: 2em 0;
    }
}