/* CONTACT */

.contact-header {
    max-width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
    margin-bottom: 4em;
}

.contact-img {
    display: inline-block;
    width: 300px;
    height: 336px;
}

.contact-img-wrapper {
    display: inline-block;
}

.description {
    display: inline-block;
    margin-left: 5em;
    max-width: 40vw;
    text-align: justify;
    position: relative;
    top: 2em;
    width: 50%;
}


/* FORM */

.contact-form {
    max-width: 35vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}

.contact-form h2{
    color: #FCB07E;
}

.contact-form button.btn.btn-primary{
    background-color: #FCB07E;
    border-color: #FCB07E;
}

textarea.form-control {
    height: 10em;
}


/* SOCIAL MEDIA */

.social-media {
    max-width: 35vw;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.socials span {
    width: 5em;
}

.socials span {
    font-size: 1.7em;
    cursor: pointer;
}

span.fb {
    color: #4267B2;
}

span.yt {
    color: #c4302b;
}

span.inst {
    color: #8a3ab9;
}

.contact-info {
    margin-top: 1em;
}

.contact-info p {
    margin: 0;
}

.contact-footer{
    background-color: #3581B8;
    color: #F3F8F2;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-top: 3em; 
}

/* MOBILE VIEW */

@media screen  and (max-width: 375px){
    .social-media {
        max-width: 80vww;
    }
}

@media screen and (max-width: 500px) {
    .social-media {
        max-width: 95vw;
    }
}

@media screen and (max-width: 1024px) {
    .contact-header{
        width: 100%;
    }

    .contact-img {
        display: block;
    }

    .contact-img-wrapper,
    .contact-header,
    .social-media {
        display: block;
        text-align: center;
    }

    .description {
        display: block;
        text-align: justify;
        display: block;
        text-align: justify;
        margin: auto;
        width: 100%;
        max-width: initial;
    }

    .contact-form {
        max-width: initial;
        margin-left: 2em;
        margin-right: 2em;
    }

    .contact-form button {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}